import { color } from "@storybook/theming"
import { Button } from "antd"
import { navigate } from "gatsby"
import React, { useEffect, useReducer } from "react"
import Topbar from "../components/organisms/topbar"
import { getRegion } from "../services/account"
import Medusa from "../services/api"
import { getCollection } from "../services/product"

export const defaultAccountContext = {
  isRoot: false,
  isLoggedIn: false,
  id: "",
  name: "",
  first_name: "",
  last_name: "",
  email: "",
  company_name: "",
  collections: [],

  regions: null,
  role: "",
  store: null,
  user: null,
}

export const AccountContext = React.createContext(defaultAccountContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "userAuthenticated":
      return {
        ...state,
        user: action.payload,
        isRoot: action.payload.isRoot,
        isLoggedIn: true,
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload?.first_name,
        last_name: action.payload?.last_name,
        company_name: action.payload?.metadata?.company_name,
      }
    case "setCollections":
      return {
        ...state,
        collections: action.payload,
      }
    case "setRegions":
      return {
        ...state,
        regions: action.payload,
      }
    case "updateUser":
      return {
        ...state,
        ...action.payload,
      }
    case "userLoggedOut":
      return defaultAccountContext
    case "userLoggedIn":
      return {
        ...state,
        isRoot: action.payload.isRoot,
        user: action.payload,
        isLoggedIn: true,
        id: action.payload.id,
        email: action.payload.email,
        store: action.payload?.store,
      }
    case "setUserInfor":
      return {
        ...state,
        first_name: action.payload?.first_name,
        last_name: action.payload?.last_name,

        isLoggedIn: true,
        company_name: action.payload?.metadata?.company_name,
        user: action.payload,
        store: action.payload?.store,
      }
    case "setUserInforAfterUpdateCompany":
      return {
        ...state,
        company_name: action.payload?.name,
      }
    case "setUserInforInMe":
      return {
        ...state,
        store: action.payload,
      }
    default:
      return state
  }
}

export const AccountProvider = ({ children, location }) => {
  const [state, dispatch] = useReducer(reducer, defaultAccountContext)
  const ignoreAuthPaths = ["/reset-password/", "/sign-up/", "/login/"]
  useEffect(() => {
    if (!state.regions) {
      const fectchRegion = async () => {
        const { response } = await getRegion()
        if (response.status == 200) {
          dispatch({
            type: "setRegions",
            payload: response.data.regions,
          })
        }
      }
      fectchRegion()
    }
    if (state.collections.length == 0) {
      const fectchCollection = async () => {
        const { response } = await getCollection()
        if (response.status == 200) {
          dispatch({
            type: "setCollections",
            payload: response.data.collections,
          })
        }
      }
      fectchCollection()
    }
    if (!state.isLoggedIn) {
      Medusa.auth
        .session()
        .then(({ data }) => {
          // console.log("hihihi", data)
          dispatch({ type: "userAuthenticated", payload: data.user })
          if (data.user.store_id) {
            Medusa.store.retrieve().then(({ data }) => {
              dispatch({ type: "setUserInforInMe", payload: data?.store })
            })
            return data
          }
          return data
          // Medusa.store.retrieve().then(({ data }) => {
          //   console.log("storeData", data)
          //   dispatch({ type: "setUserInfor", payload: data.store })
          // })
          // return data
        })
        .catch(() => {
          const currentPath = children._owner.memoizedProps.path
          if (!ignoreAuthPaths.includes(currentPath)) {
            navigate("/login")
          }
        })
    }
  }, [state.isLoggedIn])

  useEffect(() => {
    if (state.user) {
      localStorage.setItem("authority", state.user?.metadata?.access_token)
    }
  }, [state.user])

  return (
    <AccountContext.Provider
      value={{
        ...state,
        dispatch,
        session: () => {
          return Medusa.auth.session().then(({ data }) => {
            dispatch({ type: "userAuthenticated", payload: data.user })
            return data
          })
        },

        handleUpdateUser: (id, user) => {
          return Medusa.users.update(id, user).then(({ data }) => {
            dispatch({ type: "updateUser", payload: data.user })
          })
        },

        handleLogout: (details) => {
          return Medusa.auth.deauthenticate(details).then(() => {
            dispatch({ type: "userLoggedOut" })
            return null
          })
        },

        handleLogin: (details) => {
          return Medusa.auth.authenticate(details).then(({ data }) => {
            dispatch({ type: "userLoggedIn", payload: data.user })
            return data
          })
        },
      }}
    >
      {(state.isLoggedIn && state.user && !state.user.store_id) ||
      ignoreAuthPaths.includes(children?._owner?.memoizedProps?.path)
        ? children
        : state.user && (
            <div>
              {" "}
              <Topbar />
              <div className="flex flex-col items-center mt-[100px] text-primary-90 text-[18px]">
                <div>こちらのページはMrFoodの運営管理者向けるページです。</div>
                <div>
                  {`サービスを継続するには ${process.env.GATSBY_NEXT_PUBLIC_URL_STORE} にリダイレクトしてください。`}
                </div>
                <Button
                  className="button_submit mt-5"
                  size="large"
                  onClick={() => {
                    window.open(
                      `${process.env.GATSBY_NEXT_PUBLIC_URL_STORE}`,
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }}
                >
                  ユーザーのホームページへ
                </Button>
              </div>
            </div>
          )}
    </AccountContext.Provider>
  )
}
