import React from "react"

const MrFoodLogo = (props) => {
  return (
    <div className={props.className}>
      <svg
        width="135"
        height="56"
        viewBox="0 0 135 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M71.7514 39.1831V41.0476H65.1094V39.1831H71.7514ZM65.1094 42.2321L71.7514 43.0085V44.873L65.1094 44.0909V42.2321ZM65.1094 46.0574H71.7514V47.9219H65.1094V46.0574Z"
          fill="white"
        />
        <path
          d="M79.8258 39.1831V41.7844C79.8317 42.2312 79.744 42.6744 79.5683 43.0853C79.3926 43.4962 79.1329 43.8658 78.8058 44.1703C79.1341 44.4749 79.3947 44.8454 79.5705 45.2574C79.7462 45.6695 79.8332 46.1139 79.8258 46.5618V47.9219H77.9613V46.5618C77.9621 46.3693 77.9248 46.1786 77.8517 46.0005C77.7785 45.8225 77.6709 45.6606 77.5351 45.5243C77.3992 45.3879 77.2378 45.2797 77.06 45.2058C76.8823 45.132 76.6917 45.094 76.4992 45.094C76.3086 45.0913 76.1196 45.1282 75.944 45.2023C75.7684 45.2765 75.6101 45.3862 75.4791 45.5247C75.3393 45.6583 75.2288 45.8194 75.1547 45.998C75.0805 46.1765 75.0444 46.3685 75.0484 46.5618V47.9219H73.1839V46.5618C73.1782 46.1242 73.2617 45.6899 73.4293 45.2856C73.5969 44.8813 73.8451 44.5153 74.1586 44.2099C74.4606 43.895 74.8244 43.6459 75.2272 43.4782C75.6299 43.3104 76.063 43.2277 76.4992 43.2352C76.6918 43.2379 76.8829 43.2017 77.0612 43.1286C77.2394 43.0556 77.401 42.9473 77.5363 42.8101C77.6739 42.6776 77.7827 42.5181 77.8558 42.3416C77.9289 42.1651 77.9649 41.9754 77.9613 41.7844V41.0476H73.1839V39.1831H79.8258Z"
          fill="white"
        />
        <path
          d="M87.9321 39.183V44.8162C87.9506 45.2635 87.8785 45.7099 87.72 46.1286C87.5616 46.5473 87.3202 46.9297 87.0103 47.2527C86.7003 47.5757 86.3283 47.8327 85.9165 48.0083C85.5047 48.1838 85.0616 48.2744 84.614 48.2744C84.1663 48.2744 83.7233 48.1838 83.3115 48.0083C82.8997 47.8327 82.5276 47.5757 82.2177 47.2527C81.9078 46.9297 81.6663 46.5473 81.5079 46.1286C81.3495 45.7099 81.2773 45.2635 81.2958 44.8162H83.1603C83.1567 45.0081 83.1925 45.1987 83.2657 45.3761C83.3388 45.5535 83.4476 45.714 83.5854 45.8477C83.8584 46.12 84.2283 46.273 84.614 46.273C84.9996 46.273 85.3695 46.12 85.6426 45.8477C85.7822 45.7152 85.8926 45.555 85.9668 45.3774C86.0409 45.1998 86.0772 45.0086 86.0733 44.8162V44.2495H83.9481V42.402H86.0733V41.0305H83.1603V43.5354H81.3128V39.166L87.9321 39.183Z"
          fill="white"
        />
        <path
          d="M94.6664 42.6289V44.4764H89.3789V42.6289H94.6664Z"
          fill="white"
        />
        <path
          d="M102.202 39.1831V44.8163C102.22 45.2636 102.148 45.71 101.99 46.1287C101.831 46.5474 101.59 46.9297 101.28 47.2528C100.97 47.5758 100.598 47.8328 100.186 48.0084C99.7742 48.1839 99.3311 48.2744 98.8835 48.2744C98.4358 48.2744 97.9928 48.1839 97.581 48.0084C97.1692 47.8328 96.7971 47.5758 96.4872 47.2528C96.1772 46.9297 95.9358 46.5474 95.7774 46.1287C95.619 45.71 95.5468 45.2636 95.5653 44.8163H97.4242C97.4256 45.2012 97.5788 45.5699 97.8504 45.8426C98.122 46.1152 98.4901 46.2698 98.875 46.2728C99.0676 46.2755 99.2587 46.2393 99.4369 46.1663C99.6152 46.0932 99.7768 45.9849 99.9121 45.8477C100.05 45.7141 100.159 45.5536 100.232 45.3762C100.305 45.1988 100.341 45.0082 100.337 44.8163V41.0476H95.5653V39.1831H102.202Z"
          fill="white"
        />
        <path
          d="M108.809 42.6289V44.4764H103.516V42.6289H108.809Z"
          fill="white"
        />
        <path
          d="M111.988 42.6289H116.737V44.4764H111.988V47.922H110.129V39.1832H111.988V42.6289ZM113.643 38.9905C113.541 38.8858 113.473 38.7536 113.447 38.6104C113.42 38.4672 113.436 38.3194 113.493 38.1853C113.55 38.0512 113.645 37.9368 113.766 37.8563C113.887 37.7757 114.03 37.7326 114.175 37.7324C114.272 37.7318 114.367 37.7512 114.456 37.7892C114.545 37.8272 114.625 37.8831 114.691 37.9534C114.759 38.0214 114.814 38.1022 114.851 38.1911C114.888 38.2801 114.907 38.3756 114.907 38.472C114.907 38.5684 114.888 38.6638 114.851 38.7528C114.814 38.8418 114.759 38.9226 114.691 38.9905C114.625 39.0608 114.545 39.1167 114.456 39.1547C114.367 39.1927 114.272 39.2121 114.175 39.2115C114.076 39.2138 113.977 39.1952 113.886 39.1572C113.794 39.1191 113.711 39.0624 113.643 38.9905ZM115.467 38.9905C115.398 38.9233 115.342 38.8428 115.304 38.7537C115.266 38.6646 115.247 38.5688 115.247 38.472C115.247 38.3751 115.266 38.2793 115.304 38.1902C115.342 38.1011 115.398 38.0206 115.467 37.9534C115.536 37.8832 115.618 37.8273 115.709 37.7892C115.799 37.7511 115.896 37.7314 115.994 37.7314C116.093 37.7314 116.19 37.7511 116.28 37.7892C116.371 37.8273 116.453 37.8832 116.522 37.9534C116.59 38.0214 116.644 38.1022 116.681 38.1911C116.718 38.2801 116.737 38.3756 116.737 38.472C116.737 38.5684 116.718 38.6638 116.681 38.7528C116.644 38.8418 116.59 38.9226 116.522 38.9905C116.453 39.0608 116.371 39.1166 116.28 39.1547C116.19 39.1928 116.093 39.2125 115.994 39.2125C115.896 39.2125 115.799 39.1928 115.709 39.1547C115.618 39.1166 115.536 39.0608 115.467 38.9905Z"
          fill="white"
        />
        <path
          d="M30.5635 1.00893C26.7778 3.95587 23.4059 12.094 22.7088 13.7828C22.0117 15.4716 19.6882 15.3923 18.2147 16.0497C15.7608 17.1038 13.3069 19.3027 12.8139 22.3629C12.3888 24.9585 14.038 28.9029 19.1951 28.3248C23.6156 27.8375 26.2168 23.5587 25.3724 18.0105C24.5506 12.604 32.6037 6.32476 34.6326 4.68127C37.5795 2.27271 34.3549 -1.93802 30.5635 1.00893Z"
          fill="white"
        />
        <path
          d="M37.7039 27.7242C37.7036 27.3297 37.5984 26.9424 37.3993 26.6018C37.2001 26.2613 36.9141 25.9797 36.5705 25.786L29.1748 21.5129C29.0382 23.0664 28.6296 24.5837 27.9676 25.9957L30.9656 27.7242L18.8548 34.7289L6.73831 27.7299L9.51524 26.126C9.02773 24.72 8.89153 23.216 9.11853 21.7453C9.1582 21.5243 9.22622 21.3089 9.27722 21.0879L1.13344 25.7917C0.78979 25.9854 0.503763 26.2669 0.304616 26.6075C0.105468 26.948 0.000346256 27.3354 0 27.7299V44.5048L4.46576 47.089C4.46576 47.0267 4.46576 46.9643 4.46576 46.9077V31.6062L4.91915 31.8669L15.7888 37.8458V53.3286L18.8264 55.3745L21.8697 53.3286V37.8515L32.7621 31.8953L33.2155 31.6346V46.936C33.2155 46.9984 33.2155 47.055 33.2155 47.1173L37.6812 44.5331L37.7039 27.7242Z"
          fill="white"
        />
        <path
          d="M61.9116 34.3184V24.3215L57.6045 30.8444H57.5081L53.2407 24.3725V34.3014H49.9141V19H53.5411L57.5648 25.4776L61.5885 19H65.2212V34.3014L61.9116 34.3184Z"
          fill="white"
        />
        <path
          d="M75.5983 25.7688C75.1162 25.7418 74.6338 25.8178 74.1834 25.9918C73.7329 26.1657 73.3246 26.4336 72.9858 26.7776C72.351 27.4463 72.0337 28.4891 72.0337 29.9059V34.2356H68.707V22.5102H72.0337V24.8734C72.3265 24.1272 72.7996 23.4652 73.4108 22.9466C74.0893 22.441 74.9319 22.2066 75.774 22.2892V25.7688H75.5983Z"
          fill="white"
        />
        <path
          d="M76.5703 34.2357V30.7617H80.0896V34.264L76.5703 34.2357Z"
          fill="white"
        />
        <path
          d="M105.202 30.4677C104.889 31.1948 104.436 31.8533 103.87 32.4059C103.29 32.9685 102.607 33.4131 101.858 33.715C100.233 34.3498 98.4281 34.3498 96.8029 33.715C96.0574 33.4202 95.3776 32.9811 94.8024 32.4229C94.2389 31.8754 93.7884 31.2227 93.4762 30.5017C93.1563 29.7512 92.9942 28.9429 93.0002 28.1271V28.0818C92.9942 27.2678 93.1563 26.4614 93.4762 25.7129C93.7929 24.9866 94.247 24.3285 94.8137 23.7747C95.3944 23.2123 96.0773 22.7661 96.8255 22.4599C98.4508 21.8252 100.255 21.8252 101.881 22.4599C102.624 22.7588 103.303 23.1975 103.881 23.7521C104.444 24.3014 104.892 24.9561 105.202 25.6789C105.527 26.428 105.691 27.237 105.683 28.0535V28.0931C105.691 28.9097 105.527 29.7186 105.202 30.4677ZM102.402 28.0931C102.404 27.6718 102.331 27.2535 102.187 26.8577C102.043 26.478 101.828 26.1297 101.552 25.8319C101.274 25.5311 100.939 25.2884 100.566 25.1179C100.174 24.9432 99.7485 24.8543 99.3191 24.8572C98.8795 24.85 98.4432 24.935 98.0383 25.1065C97.679 25.2701 97.3569 25.5054 97.0919 25.7979C96.8245 26.0947 96.6185 26.4414 96.4855 26.818C96.3472 27.2151 96.2782 27.633 96.2815 28.0535V28.0931C96.2761 28.5147 96.3491 28.9337 96.4968 29.3286C96.7828 30.0982 97.3613 30.7235 98.1063 31.0684C98.5007 31.2495 98.9305 31.3405 99.3645 31.3348C99.8048 31.3429 100.242 31.2559 100.645 31.0798C101.006 30.9188 101.33 30.6854 101.597 30.394C101.86 30.0947 102.064 29.7486 102.198 29.3739C102.336 28.9767 102.405 28.5589 102.402 28.1385V28.0931Z"
          fill="white"
        />
        <path
          d="M119.687 30.4678C119.373 31.1952 118.918 31.8537 118.35 32.406C117.771 32.9686 117.087 33.4132 116.338 33.7151C114.715 34.35 112.912 34.35 111.289 33.7151C110.541 33.4218 109.859 32.9826 109.282 32.423C108.709 31.8679 108.252 31.203 107.941 30.468C107.629 29.7329 107.469 28.9426 107.469 28.1442V28.0989C107.462 27.2843 107.626 26.4773 107.951 25.73C108.263 25.003 108.716 24.3444 109.282 23.7918C109.864 23.2306 110.547 22.7845 111.294 22.477C112.92 21.8423 114.724 21.8423 116.349 22.477C117.093 22.7759 117.772 23.2146 118.35 23.7692C118.912 24.3198 119.363 24.9741 119.676 25.696C119.998 26.4459 120.162 27.2543 120.158 28.0706V28.1102C120.164 28.92 120.004 29.7223 119.687 30.4678ZM116.888 28.0932C116.891 27.6713 116.816 27.2525 116.667 26.8578C116.523 26.4781 116.308 26.1298 116.032 25.832C115.754 25.5312 115.419 25.2885 115.046 25.1179C114.654 24.9433 114.229 24.8544 113.799 24.8573C113.361 24.8501 112.927 24.935 112.524 25.1066C112.162 25.2687 111.838 25.5041 111.572 25.798C111.304 26.0936 111.1 26.4407 110.971 26.8181C110.828 27.2142 110.757 27.6325 110.762 28.0536V28.0932C110.759 28.5146 110.832 28.933 110.977 29.3287C111.268 30.0953 111.845 30.7192 112.586 31.0685C112.981 31.2496 113.411 31.3405 113.845 31.3349C114.285 31.343 114.722 31.256 115.125 31.0798C115.488 30.9218 115.813 30.688 116.077 30.3941C116.342 30.0963 116.546 29.7498 116.678 29.374C116.818 28.9771 116.889 28.5593 116.888 28.1386V28.0932Z"
          fill="white"
        />
        <path
          d="M131.039 33.9418V32.27C130.637 32.8138 130.135 33.2755 129.559 33.6301C128.898 34.0064 128.144 34.1909 127.383 34.1629C126.701 34.1615 126.025 34.0268 125.394 33.7662C124.751 33.502 124.171 33.104 123.694 32.5987C123.177 32.0478 122.772 31.4009 122.504 30.6945C122.191 29.8657 122.039 28.9848 122.056 28.099V28.0593C122.039 27.1735 122.191 26.2925 122.504 25.4637C122.771 24.7612 123.172 24.1166 123.683 23.5652C124.156 23.0543 124.737 22.6538 125.383 22.3921C126.021 22.1302 126.704 21.9955 127.395 21.9954C128.155 21.9754 128.907 22.1615 129.571 22.5338C130.118 22.8592 130.61 23.2706 131.027 23.7522V18H134.354V33.9702L131.039 33.9418ZM131.084 28.0593C131.089 27.6049 131.01 27.1535 130.852 26.7275C130.715 26.3464 130.507 25.9945 130.239 25.6904C129.991 25.4053 129.683 25.178 129.338 25.0243C128.992 24.8706 128.617 24.7942 128.239 24.8006C127.859 24.7963 127.482 24.8736 127.134 25.0273C126.789 25.1833 126.48 25.4091 126.227 25.6904C125.955 25.9886 125.743 26.337 125.604 26.7162C125.444 27.1457 125.365 27.6011 125.371 28.0593V28.099C125.367 28.5534 125.445 29.0048 125.604 29.4308C125.74 29.8146 125.952 30.1672 126.227 30.4678C126.48 30.7513 126.79 30.9778 127.137 31.1322C127.483 31.2866 127.859 31.3653 128.239 31.3633C128.629 31.3685 129.016 31.2893 129.372 31.1309C129.715 30.9767 130.02 30.7505 130.268 30.4678C130.535 30.1637 130.743 29.8119 130.88 29.4308C131.038 29.0048 131.117 28.5534 131.112 28.099L131.084 28.0593Z"
          fill="white"
        />
        <path
          d="M83 34.32V19H92V21.5464H85.9211V25.5628H91.1118V28.1092H85.9211V34.32H83Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

export default MrFoodLogo
