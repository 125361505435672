import React, { useContext, useEffect } from "react"
import { AccountContext } from "../../../context/account"

import MrFoodLogo from "../../fundamentals/logo/mr-food"
import ManualButton from "../../fundamentals/button/manual-button"
import LogOutButton from "../../fundamentals/button/logout-button"
import { navigate } from "gatsby"
// import "antd/dist/antd.css"

const Topbar: React.FC = () => {
  const { dispatch, isLoggedIn } = useContext(AccountContext)

  // useEffect(() => {
  //   if (!isLoggedIn) me()
  // }, [isLoggedIn])

  return (
    <div className="w-full min-h-topbar max-h-topbar bg-primary-90 border-b border-grey-20 sticky top-0 flex items-center justify-between z-40  primary-color">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/home")
        }}
      >
        <MrFoodLogo className="pl-[30px]" />
      </div>
      <div className="flex">
        <ManualButton />
        <LogOutButton />
      </div>
    </div>
  )
}

export default Topbar
