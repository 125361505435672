/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
// import { extend } from 'umi-request';
// import { notification } from 'antd';
import { removeAuthority } from "./authority"

import axios from "axios"
// config axios- fix CORS
axios.defaults.baseURL = process.env.GATSBY_MEDUSA_BACKEND_URL
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.delete['Content-Type'] = 'application/json'
// axios.defaults.headers.put['Content-Type'] = 'application/json'

// function setHeaderAuth(accessToken) {
//   axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
// }

function getCookie(value) {
  const reg = new RegExp(`(^| )${value}=([^;]*)(;|$)`)
  const arr = document.cookie.match(reg)
  try {
    if (arr) {
      return unescape(arr[2])
    }
  } catch (error) {
    return null
  }
  return null
}
const instance = axios.create({
  baseURL: process.env.GATSBY_MEDUSA_BACKEND_URL,
  timeout: false,
  withCredentials: true,
})
instance.interceptors.request // REQUEST
  .use(
    async function (config) {
      // const auth = await localStorage.getItem("authority")
      // console.log(auth)
      // let accessToken = auth.token.access_token
      const headers = { ...config.headers }
      // headers["api_access_token"] = auth
      headers["X-Requested-With"] = "XMLHttpRequest"
      headers["Content-Type"] = "application/json"
      // headers['Content-Type'] = 'multipart/form-data';
      // headers['Content-Type'] = 'application/vnd.ms-excel';
      headers["X-XSRF-TOKEN"] = getCookie("XSRF-TOKEN")
      // headers['Access-Control-Allow-Origin'] = '*';
      config.headers = headers
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
instance.interceptors.response.use(
  async function (response) {
    // if (response.status === 401) {
    //   removeAuthority()
    // }
    return {
      response,
      data: response.data,
    }
  },
  (error) => {
    console.log("error", error)
    // if (error?.response?.status == 401) {
    //   removeAuthority()
    // }

    return { response: error }
  }
)

const request = (url, options) => {
  return instance.request({ ...options, url: url })
}
export default request
