import React from "react";
import ManualIcon from "../icons/manual-icon";
import Button from "antd/lib/button"

const ManualButton = () => {
    return (
        <div>
        <button className="px-3 py-1 max-h-[25px] min-h-[25px] min-w-[186px] bg-grey-6 rounded-[5px] border-[1px] border-solid border-custom-1">
            <div className="flex items-center">
                <ManualIcon />
                <div className="text-primary-90 text-[11px] leading-[15px] font-sans font-normal text-center mx-1 ">
                    操作マニュアル
                    ダウンロード
                </div>
            </div>
        </button>
       
        </div>
    )
}

export default ManualButton